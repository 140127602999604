export const WORDS = [
  'art',
  'ash',
  'auth',
  'base',
  'beg',
  'bid',
  'bite',
  'bump',
  'cage',
  'calm',
  'cash',
  'cat',
  'chi',
  'chop',
  'cool',
  'dai',
  'dart',
  'deal',
  'debt',
  'dec',
  'dent',
  'deny',
  'dink',
  'draw',
  'dsr',
  'dump',
  'dust',
  'end',
  'file',
  'firm',
  'fix',
  'flap',
  'flip',
  'flop',
  'flow',
  'flux',
  'fold',
  'fork',
  'free',
  'frob',
  'gal',
  'gap',
  'gem',
  'grab',
  'guy',
  'heal',
  'hope',
  'hump',
  'ilk',
  'init',
  'ink',
  'join',
  'jug',
  'kick',
  'kiss',
  'line',
  'live',
  'lock',
  'lot',
  'lump',
  'mat',
  'mcd',
  'move',
  'nope',
  'one',
  'out',
  'pack',
  'pad',
  'par',
  'pie',
  'pip',
  'pot',
  'rad',
  'rate',
  'ray',
  'rely',
  'rho',
  'safe',
  'sin',
  'skim',
  'skip',
  'slip',
  'spot',
  'suck',
  'sump',
  'tab',
  'tag',
  'take',
  'tau',
  'tend',
  'thaw',
  'tic',
  'tick',
  'toll',
  'ttl',
  'urn',
  'usr',
  'vat',
  'vice',
  'vow',
  'wad',
  'wait',
  'ward',
  'when',
  'wipe',
  'wish',
  'woe',
  'yank',
]
